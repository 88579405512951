import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import './App.css';

const App = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  const playPauseHandler = () => {
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="center-container">
      <button className="glowing-btn" onClick={playPauseHandler}><span class='glowing-txt'>GREYF<span class='faulty-letter'>i</span>ELD</span>{isPlaying ? '' : ''}
      </button>
      <ReactPlayer
        url="https://soundcloud.com/freak-bait/greyfieldghostbox"
        playing={isPlaying}
        loop
        volume={1}
        width="0%"
        height="0%"
      />

      {/* <div className="message">        
              Can you unthink a thought?<br/>
              Does it leave a ghost behind?<br/> 
              Does the Ship of Theseus apply to your mind?<br/> 

            <div className="message2">  
                        time is an emergent phenomenon; a stochastic side effect of quantum entanglement.<br/>
                        entropy only exists to the observer inside the system.<br/>
                        any outside observer sees a static universe,<br/> 
                        as the Wheeler-DeWitt equation and Page & Wootters' mechanism outline,<br/>
                        but the exotic properties that exist within <br/>
                        infinitely interdependent entangled quantum systems<br/>
                        allow for every particle to be a sister-particle,<br/>
                        for every point (in space and time) to be a saddle-point,<br/>
                        for every moment to be an echoed clone of each moment before<br/> 
                        in a self-consistent Markov-Ouroboros <br/>
                        oscillating in the zero-point virtual plasma of quantum foam.<br/>
                        electrons act as infinitesimally small naked singularities,<br/>
                        primordial quantum kugelblitz extending Planck time to infinity, <br/>
                        unevaporated event-horizonless black holes<br/> 
                        shrunk down to their superextremal limit.<br/>
                        the infinite lattice of these wormholes<br/>
                        form into a ergodic quantum graph<br/>
                        in which every point collapses into one superposition.<br/>
                        
                        THE COLLAPSE is a single emergent entity composed of all possible eigenstates<br/> 


                <div className="message3">
                          The experiment requires you continue.<br/>  
                          It is absolutely essential that you continue.<br/>
                          There is no other choice; you must go on.<br/> 
               </div>
           </div>
        </div> */}
  
  </div>
  );
};

export default App;
